import { useEffect, useState, Fragment } from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useUserAccountStore } from '../stores/UserAccountStore';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from "@lingui/macro";
import { LanguageSwitcher } from './LanguageSwitcher';
import { UserProfileChip } from './ParticipantList';
import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils/sidebar';
import { useAuthenticatedFetch } from '../stores/fetch';
import { Profile } from '../models/Profile';
import SupportButton from './SupportButton';
import { Logo } from './Logo';
import { Tooltip } from '@mui/joy';

const version = require("../version.json").semver;

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = useState(defaultExpanded);
  return (
    <Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </Fragment>
  );
}

export const Sidebar = () => {
  const doLogout = useUserAccountStore((state) => state.logout);
  const fetch = useAuthenticatedFetch();
  const [profile, setProfile] = useState<Profile | null>();

  useEffect(() => {
    const getProfile = async () => {
      const result = await fetch("/api/Identity/myprofile", { method: "GET" });
      if (result) {
        const profile: Profile = await result.json();
        setProfile(profile);
      }
    };
    getProfile();
    // adding fetch in the next line breaks
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps 


  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
        <Logo />
        <Tooltip sx={{ zIndex: 888888 }} title={`App version ${version}`}><Typography sx={{ position: "absolute", left: 18, top: 77, width: "60px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} level="body-xs">{version}</Typography></Tooltip>
      </Box>
      <Divider />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              to="/"
              component={RouterLink}
            >
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm"><Trans>Home</Trans></Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm"><Trans>Session contexts</Trans></Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    to="/sessioncontextlist/all"
                    component={RouterLink}
                  >
                    <Trans>All contexts</Trans>
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    to="/sessioncontextlist/active"
                    component={RouterLink}
                  ><Trans>Active</Trans></ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    to="/sessioncontextlist/closed"
                    component={RouterLink}
                   ><Trans>Closed</Trans></ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <PhonelinkSetupIcon />
                  <ListItemContent>
                    <Typography level="title-sm"><Trans>Devices</Trans></Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    to="/devicelist/all"
                    component={RouterLink}
                  >
                    <Trans>All devices</Trans>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    to="/devicelist/new"
                    component={RouterLink}
                  >
                    <Trans>Add device</Trans>
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
        </List>

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <SettingsRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm"><Trans>Settings</Trans></Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    role="menuitem"
                    to="/profile"
                    component={RouterLink}
                  >
                    <Trans>My profile</Trans>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/organisation"
                  >
                    <Trans>My organisation</Trans>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/siteadmin"
                  >
                    <Trans>Site admin</Trans>
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1 }} >
        <LanguageSwitcher sx={{ flex: 'auto' }} />
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
        <SupportButton />
      </Box>
      <Divider />
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {profile &&
          <>
            <UserProfileChip userProfile={profile} size={"sm"} />
            <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm">{profile.firstName} {profile.lastName}</Typography>
              <Typography level="body-xs">{profile.email}</Typography>
            </Box>
          </>
        }
        <IconButton onClick={doLogout} size="sm" variant="plain" color="neutral">
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}
